import {callAISGetAPI, callGet,callPost,callPostWithoutData} from '../helper/ApiHelper'
import videojs from 'video.js'

let apiVersion;
let flixerApiPrefixUrl;
if (process.env.REACT_APP_ENV === 'production'){
    apiVersion = 'v1'
    flixerApiPrefixUrl = 'https://api.yabai-ze.com/' + apiVersion;
}
else if (process.env.REACT_APP_ENV === 'staging') {
    apiVersion = 'v1'
    flixerApiPrefixUrl = 'https://stageapi.yabai-ze.com/' + apiVersion;
}
else{
    apiVersion = 'v1'
    flixerApiPrefixUrl = 'https://devapi.yabai-ze.com/' + apiVersion;
}

export async function getEpisodeList( mediaId ,apiKey){
    return callGet(flixerApiPrefixUrl +'/previewEpisodeList/'+mediaId + '/dash/'+ apiKey)
}

export async function getEpisode( mediaId, episode, apiKey){

    var drmVideoType = '';
    if (videojs.browser.IS_SAFARI) {
        drmVideoType = 'hls';
    } else {
        drmVideoType = 'dash';
    }

    return callGet(flixerApiPrefixUrl +'/previewEpisode/'+mediaId+'/'+episode+ '/' + drmVideoType+'/'+ apiKey);
}

export async function getTitle( mediaId, apiKey ){
    return callGet(flixerApiPrefixUrl +'/previewTitle/'+mediaId+'/'+ apiKey);
}

// export async function getSearchList( searchStr ){
//     return callGet(flixerApiPrefixUrl +'/search/'+searchStr)
// }

export async function getSearchList( searchStr ){
    return callPost(flixerApiPrefixUrl + '/searchTitle', {
        keyword: searchStr
    })
}

export async function getHomePage(){
    return callGet(flixerApiPrefixUrl +'/home')
}

export async function getContinueWatching(){
    return callPostWithoutData(flixerApiPrefixUrl +'/contwatch')
}

export async function getRental(){
    return callGet(flixerApiPrefixUrl +'/rental')
}

export async function getCategoryList(){
    return callGet(flixerApiPrefixUrl +'/categories')
}

export async function getCategoryFromSlug(slug){
    slug = slug.replace(" ", "_");
    return callGet(flixerApiPrefixUrl +'/webCategory/'+encodeURI(slug))
}

export async function getCharacterList(id){
    return callGet(flixerApiPrefixUrl +'/webCharacter/' + id )
}

export async function getShelf(id){
    return callGet(flixerApiPrefixUrl +'/webShelf/' + id )
}

export async function guestLogin(){
    return callPost(flixerApiPrefixUrl +'/guestlogin' , { 'device_id': "web" })
}

export async function refreshToken(){
    return callGet(flixerApiPrefixUrl +'/refreshToken')
}

export async function getProfile(){
    return callGet(flixerApiPrefixUrl +'/profile')
}

export async function logout(){
    return callPostWithoutData(flixerApiPrefixUrl +'/logout')
}


export async function profileUpload( base64Image ){
    return callPost(flixerApiPrefixUrl +'/profileUpload', {data: base64Image} )
}


export async function startWatching(titlePremium, episodePremium, mediaId, episode ){
    return callPostWithoutData(flixerApiPrefixUrl + '/startWatching/' + titlePremium + '/' + episodePremium + '/' + mediaId + '/' + episode)
}

export async function watching( mediaId, episode, currentTime ){
    if( currentTime <= 0 ) return // จะไม่เรียก API กรณีที่ currentTime น้อยกว่าหรือเท่ากับ 0
    return callPostWithoutData(flixerApiPrefixUrl +'/watching/' + mediaId + '/' + episode + '/' + currentTime)
}

export async function endWatching(){
    return callPostWithoutData(flixerApiPrefixUrl +'/endWatching')
}

export async function callApiLike( mediaId, likeStatus ){
    return callPost(flixerApiPrefixUrl +'/like/'+ mediaId , {like_status: likeStatus} )
}

export async function callAISRequestOTPApi( mobileNumber ){
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=generateThirdPartyOTP&mobile='+ mobileNumber + '&platform=web&token=')
}

export async function callAISConfirmOTPApi(mobileNumber, otp){
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=loginThirdPartyOTP&mobile='+mobileNumber+'&otp='+otp+'&platform=web&token=')
}

export async function callAISPackageListApi(mobileNumber) {
    return callAISGetAPI('https://api.flixerapp.com/ais/aisweb.php?action=packageList&mobile='+mobileNumber+'&platform=web&token=')
}

export async function callApiRedeem(code){
    return callPost(flixerApiPrefixUrl +'/redeem' , {redeem_code: code} )
}

export async function callApiRentalList(){
    return callGet(flixerApiPrefixUrl +'/rentalList')
}

export async function callApiSubscriptionList(){
    return callGet(flixerApiPrefixUrl + '/subscriptionList')
}

export async function callApiStripeSubscriptionList(){
    return callGet(flixerApiPrefixUrl + '/stripeSubscriptionList')
}

export async function callApiSubscriptionPurchase(productId, price){
    return callPost(flixerApiPrefixUrl + '/subscriptionPurchase', { product_id: productId, price: price })
}

export async function callApiSubscriptionStatus(){
    return callGet(flixerApiPrefixUrl + '/subscriptionStatus')
}

export async function callApiTopupStatus(){
    return callGet(flixerApiPrefixUrl + '/topupStatus')
}

export async function callApiCancelSubscription(){
    return callGet(flixerApiPrefixUrl + '/subscriptionCancel')
}

export async function callApiRentalBuy( productId, price ){
    return callPost(flixerApiPrefixUrl + '/rentalBuy', { product_id: productId, price: price })
}

export async function callApiRentalStatus(){
    return callGet(flixerApiPrefixUrl + '/rentalStatus')
}


export async function emailLogin(username, password){

    return callPost(flixerApiPrefixUrl +'/emaillogin', {
        email: username,
        password: password, 
        devicie_id: 'web',
        screen_density: 'xxhdpi',
        mac_address: ''
    })
}

export function emailSignUp(username, password, showAgreement, dateOfBirth){

    return callPost(flixerApiPrefixUrl +'/emailregister', {
        email: username,
        password: password, 
        mac_address: '',
        alreadyShowAgreement: showAgreement,
        userDateOfBirth: dateOfBirth
    })
}


export function sendVerifyEmail(tokenVerify){

    return callPost(flixerApiPrefixUrl +'/sendVerifyAccountEmailWithoutLogin', {
        verifyEmailToken: tokenVerify,
    })
}

export function fbLogin(fbData, showAgreement, dateOfBirth){
    return callPost(flixerApiPrefixUrl +'/fblogin', {
        ...fbData, 
        device_id: 'web',
        mac_address: '',
        alreadyShowAgreement: showAgreement,
        userDateOfBirth: dateOfBirth
    })
}


export function appleLogin(appleData, showAgreement, dateOfBirth){
    return callPost(flixerApiPrefixUrl +'/webAppleLogin', {
        ...appleData, 
        device_id: 'web',
        alreadyShowAgreement: showAgreement,
        userDateOfBirth: dateOfBirth
    })
}

export function googleLogin(googleLogin, dateOfBirth){
    return callPost(flixerApiPrefixUrl +'/googleLogin', {
        device_id: 'web',
        idToken: googleLogin.tokenId,
        signInPlatform : 'web',
        userDateOfBirth: dateOfBirth
    })
}

export function googleLoginOAuthWeb(userObject, showAgreement, dateOfBirth){
    return callPost(flixerApiPrefixUrl +'/webGoogleLogin', {
        name: userObject.name,
        email : userObject.email,
        picture: userObject.picture,
        userId: userObject.sub,
        alreadyShowAgreement: showAgreement,
        userDateOfBirth: dateOfBirth
    })
}

export function forgetPassword(email){
    return callPost(flixerApiPrefixUrl +'/forgot', {
        email: email
    })
}

export async function receiptValidator(){
    return callGet(flixerApiPrefixUrl +'/receiptValidator', {
        receipt_data: '',
        platform: 'web',
        end_point: 'production',
        package_name: '',
        product_id: '',
        purchase_token: ''
    })
}


export async function checkWebCredentialExist(){
    return callGet(flixerApiPrefixUrl +'/checkWebCredentialExist')
}

export async function changePassword(){
    return callPost(flixerApiPrefixUrl +'/changepassword', {device_id: 'web'})
}

export async function sendBeaconEndWatch(mediaId, episode){
    navigator.sendBeacon(flixerApiPrefixUrl + '/endWatch/'+mediaId+'/'+episode)
}

export async function checkMaintenance(){
    return callGet(flixerApiPrefixUrl +'/maintenance')
}

export async function callApiTopupList(){
    return callGet(flixerApiPrefixUrl +'/topupList')
}

export async function callApiTopupBuy(productId, price){
    return callPost(flixerApiPrefixUrl +'/topupBuy', {
        product_id: productId,
        price: price
        // product_id: 'com.flixer.flixer.topup30',
        // price: 79
    })
}

export async function getStripeClientSecret(){

    return callPostWithoutData('http://localhost:4000/create-payment-intent',{
        items: [{ id: "xl-tshirt" }]
    })
}

export async function paymentStripeSubscription(){

    return callPostWithoutData('http://localhost:4242/create-checkout-session',{
        // items: [{ id: "xl-tshirt" }]
    })
}

export async function callApiStripeCreateCheckoutSession(stripePriceId, checkoutMode){
    return callPost(flixerApiPrefixUrl + '/stripeCreateCheckoutSession', { 
        stripePriceId: stripePriceId, 
        checkoutMode: checkoutMode 
    })
}

export async function callApiStripeCreateCustormerPortalSession(){
    return callPost(flixerApiPrefixUrl + '/stripeCreateCustormerPortalSession', { 
    })
}

export function consentlog(message){
    return callPost(flixerApiPrefixUrl +'/cookieConsentLog', {
        message: message
    })
}

export async function removeAccount(){
    return callGet(flixerApiPrefixUrl +'/removeAccount')
}

export async function stripeGetPricingTable(){
    return callGet(flixerApiPrefixUrl +'/stripeGetPricingTable')
}

export async function getVerifyAccountStatus() {
    return callGet(flixerApiPrefixUrl +'/getVerifyAccountStatus')
}

export async function sendVerifyAccountEmail() {
    return callGet(flixerApiPrefixUrl +'/sendVerifyAccountEmail')
}

export async function getIPAndCountry() {
    return callGet(flixerApiPrefixUrl +'/getIPAndCountry')
}

export async function getTermOfUseContentPath() {
    return callPost(flixerApiPrefixUrl +'/getTermOfUseContentPath', {
        'device_id': "web"
    })
}

export async function getPrivacyPolicyContentPath() {
    return callPost(flixerApiPrefixUrl +'/getPrivacyPolicyContentPath', {
        'device_id': "web"
    })
}

export function updateUserBirthDate(birthDay){
    return callPost(flixerApiPrefixUrl +'/updateUserBirthDate', {
        userDateOfBirth: birthDay
    })
}
export async function getAgreementTextContent() {
    return callGet(flixerApiPrefixUrl +'/getAgreementPageContent')
}