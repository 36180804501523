import React, {useState, useEffect, useCallback} from 'react'
import { getCategoryList } from '../../api'
import SearchBar from './SearchBar'
import AccountButton from './AccountButton';
import PremiumButton from '../../components/navbar/PremiumButton';
import HomeButton from '../../components/navbar/HomeButton';
import FlixerLogo from '../../components/navbar/FlixerLogo';
import CategoriesButton from '../../components/navbar/CategoriesButton';
import FlixerPlusButton from '../../components/navbar/FlixerPlusButton';
import MobileSlideMenu from '../../components/navbar/MobileSlideMenu';
import MobileSearchBar from './MobileSearchBar';
import RedeemButton from '../../components/navbar/RedeemButton';
import PremiumButtonMobile from '../../components/navbar/PremiumButtonMobile';
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper';
import MenuNavButtonMovie from '../../components/navbar/MenuNavButtonMovie';
import MenuNavButtonAnime from '../../components/navbar/MenuNavButtonAnime';
import MenuNavButtonDocumentary from '../../components/navbar/MenuNavButtonDocumentary';
import { useResizeDetector} from 'react-resize-detector/build/withPolyfill';
import MenuNavButtonIdol from '../../components/navbar/MenuNavButtonIdol';
import MenuNavButtonGundam from '../../components/navbar/MenuNavButtonGundam';
import MenuNavButtonMaskedRider from '../../components/navbar/MenuNavButtonMaskedRider';
import MenuNavButtonChildren from '../../components/navbar/MenuNavButtonChildren';
import LanguageButton from './LanguageButton';
import { useParams } from 'react-router-dom';
import LanguageButtonMobile from './LanguageButtonMobile';
import ArticleButton from '../../components/navbar/ArticleButton';


const NavBar = () => {

    const [type, setType] = useState("list")

    let cookieCurrentLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    const onResize = useCallback(( width, height ) => {

        const clientWith = document.documentElement.clientWidth

        if(clientWith <= 1024) {
            setType("hamburger")
        } else {
            setType("list")
        }
        
    }, []);
    const { ref } = useResizeDetector({ onResize });

    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        // getCategoryList().then( (res) => {
        //     handleGetCategoryListResponse(res)
        // })
    }, [])

    const handleGetCategoryListResponse = ( response ) => {
        setCategoryList(response.result)
    }


    return (
        <div className='nav-contrainer' ref={ref}>
            {/* ----- MobileMode ----- */}
            {
                // (type !== "list") 
                // ?

                // <div className={'nav-bar text-end justify-content-between ' + type + "-mode"}>
                //     <div className='nav-bar-left'>
                //         <MobileSlideMenu categoryList={categoryList}/>
                //         <FlixerLogo />
                //     </div>
                //     <div className='nav-bar-right'>
                //         {/* {
                //             isUserPremium() // ถ้าเป็น Premium จะไม่แสดงปุ่ม
                //             ?
                //             ''
                //             :
                //             <PremiumButtonMobile />
                //         } */}
                //         <MobileSearchBar />
                //         <LanguageButtonMobile languageCurrent={cookieCurrentLanguage}/>
                //         <AccountButton />
                //     </div>
                // </div>
                // :
                <div className={'nav-bar text-end justify-content-between ' + type + "-mode"} >
                    <div className='nav-bar-left'>
                    {/* <FlixerLogo /> */}
                    </div>
                    <div className='nav-bar-middle'>
                        {/* <HomeButton />
                        <CategoriesButton categoryList={categoryList}/> 
                        <ArticleButton/> */}
                    </div>
                    <div className='nav-bar-right'>
                        {/* <div className=' search-bar' style={{width:'200px'}}>
                            <SearchBar />
                        </div>  */}
                        <LanguageButton languageCurrent={cookieCurrentLanguage}/>
                        {/* <AccountButton /> */}
                        
                    </div>
                </div>

            }

        </div>
    )
}

export default NavBar
