import { homeSetShelf, siteSetForcePlayVideo } from '../actions';
import { store } from '../index';
import validator from 'validator';
import { isUserPremium } from './ReduxStoreHelper';
import i18n from '../components/i18n';

export const loadScriptAsync = (url) => {
    return new Promise(function (resolve, reject) {
        var r = false,
            t = document.getElementsByTagName('script')[0],
            s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = url;
        s.async = true; //@ts-ignore

        s.onload = s.onreadystatechange = function () {
            //@ts-ignore
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                resolve(this);
            }
        };

        s.onerror = s.onabort = reject;

        if (t && t.parentNode) {
            t.parentNode.insertBefore(s, t);
        }
    });
}

export const formatDurationString = (durationInSec) => {
    var sec_num = parseInt(durationInSec, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (minutes < 10 && hours !== 0) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    if (hours === 0) return minutes + ':' + seconds;
    return hours + ':' + minutes + ':' + seconds;
}

export const isSearchPage = (path) => {
    return path.search('/search') !== -1
}

export const isHomePage = (path) => {
    return path.search(/^\/$/) !== -1
}
export const isCategoryPage = (path) => {
    return path.search('/category/') !== -1
}
export const isFlixerPlusPage = (path) => {
    return path.search('/flixer-plus') !== -1
}
export const isRedeemPage = (path) => {
    return path.search('/redeem') !== -1
}

export const isVideoPlayerPage = (path) => {
    return path.search('/video/') !== -1;
}

export const isCategoryMenuMovie = (path) => {
    return path.search('/category/movie') !== -1;
}

export const isCategoryMenuChildren = (path) => {
    return path.search('/category/children') !== -1;
}

export const isCategoryMenuIdol = (path) => {
    return path.search('/category/idol') !== -1;
}

export const isCategoryMenuGundam = (path) => {
    return path.search('/category/gundam') !== -1;
}

export const isCategoryMenuMaskedRider = (path) => {
    return path.search('/category/masked rider') !== -1;
}

export const isPageNotFound = (path) => {
    return path.search('/pageNotFound') !== -1;
}

export const isManageAccountPage = (path) => {
    return path.search('/accounts') !== -1;
}

// export const isOtherPackages = (path) => {
//     return path.search('/otherpackages') !== -1
// }

export const search = (history, searchStr) => {
    var language = getCookieLanguage();

    if (language !== "en") {
        // ดูว่าพึ่งเริ่มพิมพ์ Search หรือไม่ ถ้าพึ่งเริ่มพิมพ์ครั้งแรกจะทำการ push history เพื่อใช้สำหรับ back กลับไปหน้าเดิมได้เมื่อ search เสร็จ
        // ถ้าพึ่งเริ่มพิมพ์ Search ครั้งแรกจะยังไม่มี state ตัวนี้มา
        if (!history.location.state || !history.location.state.searching) {
            history.push(window.flixer.homeUrl + "/" + language + "/search/?q=" + searchStr, { 'flixerWeb': true, 'searching': true })
        } else {
            history.replace(window.flixer.homeUrl + "/" + language + "/search/?q=" + searchStr, { 'flixerWeb': true, 'searching': true })
        }
    } else {
        if (!history.location.state || !history.location.state.searching) {
            history.push(window.flixer.homeUrl + "/search/?q=" + searchStr, { 'flixerWeb': true, 'searching': true })
        } else {
            history.replace(window.flixer.homeUrl + "/search/?q=" + searchStr, { 'flixerWeb': true, 'searching': true })
        }
    }

}


export const goToPage = (history, url, scrollToTop, isReplaceHistory = false) => {
    if (scrollToTop) window.scrollTo(0, 0)

    // กรณีที่หน้าที่จะไปตรงกับหน้าปัจจุบัน จะไม่ push history เพราะไม่มีความจำเป็นต้องไปหน้าซ้ำ
    if( window.flixer.homeUrl + window.location.pathname === url ){
        // ถ้าเป็นหน้า Video จะสั่งให้เริ่มเล่น Video
        if (url.includes('/video/')) {
            store.dispatch(siteSetForcePlayVideo(true))
        }
    } else {
        if (isReplaceHistory) {
            // Replace คือจะไม่เก็บ URL ก่อนหน้าไว้ใน history จะใช้กรณีที่ต้องการ override หน้านั้นๆให้เป็นอีกหน้าหนึ่ง
            history.replace(url, { 'flixerWeb': true });
        } else {
            history.push(url, { 'flixerWeb': true });
        }
    }
}

export const goToEpisode = (history, mediaId, episode, scrollToTop = true, isReplaceHistory = false) => {
    var language = getCookieLanguage();
    var  apiKeyCookie = document.cookie.replace(/(?:(?:^|.*;\s*)apiKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/video/' + mediaId + '/' + episode + '/' + apiKeyCookie, scrollToTop, isReplaceHistory)
    } else {
        goToPage(history, window.flixer.homeUrl + '/video/' + mediaId + '/' + episode + '/' + apiKeyCookie, scrollToTop, isReplaceHistory)
    }
}

export const goToHomePage = (history, scrollToTop = true) => {
    // ต้องลดขนาด shelf ก่อนเปลี่ยนหน้า เนื่องจาก shelf มีขนาดใหญ่มาก ถ้าไม่ลดขนาดก่อน เวลาเปลี่ยนหน้าจะพยายาม render shelf เดิมก่อนและค่อยเปลี่ยน shelf ตาม response ใหม่เว็บจะหน่วงมาก
    let state = store.getState()
    let shelf = state.home.shelf
    if (shelf && shelf.length > 0) {
        store.dispatch(homeSetShelf(shelf.slice(0, 3)))
    } else {
        store.dispatch(homeSetShelf([]))
    }
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/', scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/', scrollToTop)
    }
}

export const goToSeeAllPage = (history, id, scrollToTop = true) => {
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + "/" + language + '/see-all/' + id, scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/see-all/' + id, scrollToTop)
    }
}

export const goToDownloadApp = () => {
    window.location.href = "https://yabai-ze.com/download"
}

export const goToFlixerPlusPage = (history, scrollToTop = true) => {
    // ต้องลดขนาด shelf ก่อนเปลี่ยนหน้า เนื่องจาก shelf มีขนาดใหญ่มาก ถ้าไม่ลดขนาดก่อน เวลาเปลี่ยนหน้าจะพยายาม render shelf เดิมก่อนและค่อยเปลี่ยน shelf ตาม response ใหม่เว็บจะหน่วงมาก
    let state = store.getState()
    let shelf = state.home.shelf
    if (shelf && shelf.length > 0) {
        store.dispatch(homeSetShelf(shelf.slice(0, 3)))
    } else {
        store.dispatch(homeSetShelf([]))
    }
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/flixer-plus', scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/flixer-plus', scrollToTop)
    }
}

export const goToCategoryContent = (history, slug, scrollToTop = true) => {
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + "/" + language + '/category/' + slug, scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/category/' + slug, scrollToTop)
    }
}

export const goToVideo = (history, mediaId, scrollToTop = true) => {
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/video/' + mediaId, scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/video/' + mediaId, scrollToTop)
    }
}

export const getCookieLanguage = () => {
    let cookieCurrentLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if(cookieCurrentLanguage === '' || cookieCurrentLanguage === null) {
        cookieCurrentLanguage = "en"
    } else {
        if (cookieCurrentLanguage !== 'en' && cookieCurrentLanguage !== 'es') {
            cookieCurrentLanguage = "en"
        }
    }

    i18n.changeLanguage(cookieCurrentLanguage)

    return cookieCurrentLanguage;
}

export const goToCharacterContent = (history, id, scrollToTop = true) => {
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/character/' + id, scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/character/' + id, scrollToTop)
    }
}



export const goToRedeemPage = (history, scrollToTop = true) => {
    var language = getCookieLanguage();
    if (language !== "en") {
        goToPage(history, window.flixer.homeUrl + '/' + language + '/redeem', scrollToTop)
    } else {
        goToPage(history, window.flixer.homeUrl + '/redeem', scrollToTop) 
    }
}

//  จะเป็นการไปหน้า Home แบบที่ Clear State ทิ้งทั้งหมด
export const locationToHomePage = () => {
    var language = getCookieLanguage();
    if (language !== "en") {
        window.location.href = window.flixer.homeUrl + '/' + language + '/';
    } else {
        window.location.href = window.flixer.homeUrl + '/';
    }
}

// อันนี้จะเช็คได้ initial ครั้งเดียว width จะไม่อัพเดทตามขนาดจอที่เปลี่ยนไป
export const isMobileWidth = () => {
    const clientWith = document.documentElement.clientWidth;
    return clientWith <= 768
}

export const checkIsMobileWidth = (width) => {
    return width <= 1024
}

export const firebaseLogEvent = (eventName, params) => {
    if (process.env.REACT_APP_ENV !== 'production') return;
    window.firebase.analytics().logEvent(eventName, params)
}

export const showLoading = () => {
    let loading = document.getElementById("loading-container")
    loading.style.display = 'block'
    setTimeout(() => {
        loading.style.opacity = 1
    }, 50)
}

export const hideLoading = () => {
    let loading = document.getElementById("loading-container")
    if (loading) {
        loading.style.opacity = 0
        setTimeout(() => {
            loading.style.display = 'none'
        }, 500)
    }
}


export const isUserPremiumStatusActive = (status) => {
    return status === "active" || status === "trial"
}


export const findEpisodeIndexFromEpisodeId = (episodeList, episodeId) => {

    let episodeIndex = 0;

    episodeList.forEach((episode, index) => {

        if (episode.episode === episodeId) {
            episodeIndex = index;
        }

    })

    return episodeIndex;

}

// เนื่องจาก Component AllMediaContentList จะรับ list ตาม format [ { id: string, name: string, imageUrl: string }, ... ]
// จึงต้องทำให้ List เป็นตาม Format ที่ต้องการ
export const makeListSupportAllMediaContentListFormat = (list, listType) => {

    if (listType === 'search') {
        list = list.map((details) => {
            return { id: details.id, name: details.name, imageUrl: details.poster_url }
        })
    } else {
        list = list.map((details) => {
            return { id: details.id, name: details.name, imageUrl: details.thumbnail_url }
        })
    }

    return list
}


export const convertUnderscoreObjectKeyToCamelCaseObjectKey = (object) => {

    let newObject = {}

    Object.keys(object).forEach(key => {
        let camelCasedKey = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() })
        newObject[camelCasedKey] = object[key]
    })

    return newObject

}


export const validateEmail = (email) => {
    return validator.isEmail(email)
}

export const validatePassword = (password) => {
    // string there has to be at least one number -> (?=.*\d)
    // and at least one letter -> (?=.*[A-Za-z])
    // and it has to be a number, a letter or one of the following: !@#$% -> [0-9A-Za-z!@#$%]
    // and there have to be 8-12 characters -> {8,12}
    const regex = new RegExp(/^(?=.*\d)(?=.*[A-Za-z])[0-9A-Za-z!@#$%_]{8,99}$/);
    return regex.test(password)
}

export const calculateSubscriptionProductPeriodInDay = (startDate, endDate) => {

    const moment = require('moment')

    let startDateObj = moment(startDate)
    let endDateObj = moment(endDate)

    let periodInDay = endDateObj.diff(startDateObj, 'day')

    return periodInDay
}


export const getSubscriptionProductPeriodInDayFromReceiptValidatorResponse = (response) => {

    let productPeriodInDay = ""

    if (!response.error) {
        if (response.result && response.result.receipt_summary) {

            let receiptSummary = response.result.receipt_summary;
            let purchaseDate = receiptSummary.purchase_date
            let expireDate = receiptSummary.expires_date

            productPeriodInDay = calculateSubscriptionProductPeriodInDay(purchaseDate, expireDate)

            // ถ้า periodInDay <= 0 แสดงว่า ตัวแปรที่ส่งมาไม่ถูกต้อง จะไม่แสดงผล
            if (productPeriodInDay <= 0) {
                productPeriodInDay = ""
            }
        }
    }

    return productPeriodInDay

}

export const getRentalPriceFromMediaDetail = (mediaDetails) => {

    let price = "";

    if (isUserPremium()) {
        price = mediaDetails.price_premium
    } else {
        price = mediaDetails.price
    }

    return price
}


export const getRentalProductIdFromMediaDetail = (mediaDetails) => {

    let price = "";

    if (isUserPremium()) {
        price = mediaDetails.product_id_premium
    } else {
        price = mediaDetails.product_id
    }

    return price
}

// ส่งวันหมดอายุเข้ามาเป็น String TimeZone เป็น Bangkok เพื่อประมวลผลหาจำนวนวันที่เหลือก่อนหมดอายุ
export const getDayLeft = (expireDateStr) => {
    const moment = require('moment-timezone')

    let expireDate = moment(expireDateStr).tz('Asia/Bangkok')
    let currentDate = moment.tz('Asia/Bangkok')

    let diffInSecond = expireDate.diff(currentDate, 'second')

    if (diffInSecond <= 0) {
        // กรณีหมดอายุแล้วจะไม่มีส่งมา แต่ก็ Handle เผื่อไว้
        return 0;
    } else {

        let diffInDay = expireDate.diff(currentDate, 'days', true)
        // ปัดเศษขึ้นเสมอ (เศษวันจะนับเป็น 1 วัน)
        diffInDay = Math.ceil(diffInDay)

        if (diffInDay === 1) {
            return diffInDay
        } else {
            return diffInDay
        }
    }
}


// ส่งวันหมดอายุเข้ามาเป็น String TimeZone เป็น Bangkok เพื่อประมวลผลหาจำนวนวันที่เหลือก่อนหมดอายุ
export const getDayLeftString = (expireDateStr) => {
    const moment = require('moment-timezone')

    let expireDate = moment(expireDateStr).tz('Asia/Bangkok')
    let currentDate = moment.tz('Asia/Bangkok')

    let diffInSecond = expireDate.diff(currentDate, 'second')

    if (diffInSecond <= 0) {
        // กรณีหมดอายุแล้วจะไม่มีส่งมา แต่ก็ Handle เผื่อไว้
        return '-';
    } else {

        let diffInDay = expireDate.diff(currentDate, 'days', true)
        // ปัดเศษขึ้นเสมอ (เศษวันจะนับเป็น 1 วัน)
        diffInDay = Math.ceil(diffInDay)

        if (diffInDay === 1) {
            return diffInDay + " day left"
        } else {
            return diffInDay + " days left"
        }
    }
}

// ดูว่า Episode ที่เลือก มีใน episodeList หรือไม่
export const isEpisodeValid = (selectedEpisode, episodeList) => {
    const isEpisodeValid = (episode) => episode.episode === selectedEpisode;
    return episodeList.findIndex(isEpisodeValid) !== -1
}


export function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const consoleLogHelper = (title, data = "") => {
    if (process.env.REACT_APP_ENV === 'production') return;
}

export const consoleErrorHelper = (title, data = "") => {
    if (process.env.REACT_APP_ENV === 'production') return;
    console.error(title, data);
}


export const alertErrorByCode = (errorCode, title, message) => {

    let errorString = window.flixer.ERROR[errorCode] + " (" + errorCode + ")"

    if (message) {
        errorString = errorString.replace("%s", message);
    }

    alert(errorString)

}

export const checkLanguageCookieFromUrl = (language) => {
    let cookieLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)currentLanguage\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            if(cookieLanguage === '' || cookieLanguage === null) {
                cookieLanguage = "en"
            } else {
                if (cookieLanguage !== 'en' || cookieLanguage !== 'es') {
                    cookieLanguage = "en"
                }
            }

        if(window.location.pathname !== '/' + cookieLanguage + '/') {
            i18n.changeLanguage(language)
            return false
        }

        return true
}

export const checkLinkUrl = (language, lastValueFromUrl) => {
    // เช็คว่า url อักษรย่อประเทศ เช่น /us/ ที่ใส่มาตรงกับภาษาปัจจุบันที่ใช้ในเว็บหรือเปล่า
    processCheckLanguageFromUrl(language)
    //ส่งข้อมูลตัวสุดท้ายที่ได้จาก url เพื่อไปตรวจสอบว่ามีการพิมพ์ URLเกินหรือเปล่า
    checkUrlLastValueThisUrl(lastValueFromUrl)
}

export const processCheckLanguageFromUrl = (language) => {
    // เช็คว่า url อักษรย่อประเทศ เช่น /us/ ที่ใส่มาตรงกับภาษาปัจจุบันที่ใช้ในเว็บหรือเปล่า
    if(checkLanguageCookieFromUrl() === false) {
        if(language === "en" || language === "es" || language == null) {
            if (language === "en" || language === "es") {
                document.cookie = 'currentLanguage' + "=" + language + "; max-age=" + 31622400 + "; path=/" + ";domain=" + window.flixer.cookieDomainDefault; //กำหนดให้ cookie นี้ใช้ได้ 1 ปี
                if (language === "en") {
                    window.location.replace("/"); 
                }
            } else {
                document.cookie = 'currentLanguage' + "=" + 'en' + "; max-age=" + 31622400 + "; path=/" + ";domain=" + window.flixer.cookieDomainDefault; //กำหนดให้ cookie นี้ใช้ได้ 1 ปี
            }
        } else {
            document.cookie = 'currentLanguage' + "=" + 'en' + "; max-age=" + 31622400 + "; path=/" + ";domain=" + window.flixer.cookieDomainDefault; //กำหนดให้ cookie นี้ใช้ได้ 1 ปี
            isCheckPageNotFoundFromUrl(language)                    
        }
    }
}

export const isCheckPageNotFoundFromUrl = (languageFromUrl) => {
    let lang = languageFromUrl
    //ปัจจุบันภาษามีแค่ es
    if (lang !== "es") {
        //ถ้าพบว่าข้อมูลที่ส่งมาไม่ใช่ตัวย่อของภาษาให้ทำการเรียกหน้า pageNotFound
        window.location.replace("/pageNotFound");
        return false
    } 

    return true
}

export const checkUrlLastValueThisUrl = (lastValueFromUrl) => {
    if (window.location.pathname.includes(lastValueFromUrl)) {
        const arrayTextUrlSplit = window.location.pathname.split(lastValueFromUrl);
        if (arrayTextUrlSplit[1] !== '') {
            if (arrayTextUrlSplit[1] !== '/') {
                window.location.replace("/pageNotFound");
            }
        }
    }
}

export const checkUrlVideoPage = (url) => {
    var language = getCookieLanguage();
    var valueOtherPosition;
        const arrayTextUrlSplit = url.split('/');
        if(language === "es") {
            valueOtherPosition = arrayTextUrlSplit[6]
        } else {
            valueOtherPosition = arrayTextUrlSplit[5]
        }
        if (valueOtherPosition !== undefined) {
                window.location.replace("/pageNotFound");
        } 
}

// function getAge(dateString) 
export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
}