import React,{useRef, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { loginModalSetHidden, mediaSetCanView, mediaSetIsCurrentEpisodePremium, premiumModalSetShow, selectEpisode, selectMedia } from '../../actions'
import {alertErrorByCode, goToEpisode, hideLoading, showLoading} from '../../helper/Helper'
import { isUserLoggedIn, isUserPremium } from '../../helper/ReduxStoreHelper'
import { useDispatch, useSelector } from 'react-redux'
import { callApiRentalBuy, getEpisode } from '../../api'
// import DesktopSampleImage from '../../images/slideshow/slideshow-desktop-sample.png'
import DummyImage from '../../images/slideshow/slideshow-mobile-sample.png'

const EpisodeContent = ({ episode }) => {

    let history = useHistory();

    const dispatch = useDispatch()

    const currentMedia = useSelector(state => state.media)
    const currentMediaRef = useRef();
    currentMediaRef.current = currentMedia;

    const handleOnClick = () => {

        if(window.flixer.episodeClickTimeoutID !== ''){
            clearTimeout(window.flixer.episodeClickTimeoutID)
        }

        // ใส่ timeout ไว้ 300 ms ก่อนที่จะเปลี่ยน Episode ป้องกันกรณีกดเปลี่ยนรัวๆจนพัง
        window.flixer.episodeClickTimeoutID = setTimeout( () => {  

            getEpisode(episode.title, episode.episode).then( (res) => handleEpisodeResponse(res) )

            const handleEpisodeResponse = ( response ) => {
                // This is src for player
            dispatch(mediaSetCanView(response.can_view));
            dispatch(mediaSetIsCurrentEpisodePremium(episode.premium));
            // dispatch(selectMedia(episode.id));
            // dispatch(selectEpisode(episode.episode));

            // if( (currentMediaRef.current.canView !== 1 && currentMediaRef.current.canView !== '1') ){
                
            //     if( !isUserLoggedIn() ){
            //         dispatch(loginModalSetHidden(false))
            //     }else{

            //         // Handle Case Episode ที่เป็น FreeTrial คือ ถ้า CanView เป็น 0 แต่ว่า episode นี้ ไม่ได้เป็น premium จะถือว่าเป็น Trial Episode ให้กดไปที่ Episode นั้นได้
            //         if( !episode.premium ){
            //             goToEpisode(history, currentMediaRef.current.id, episode.episode, false)
            //         }else if( currentMediaRef.current.isRentalType && !currentMediaRef.current.isRented ){
            //             handleRental()
            //         }else if( currentMediaRef.current.isCurrentEpisodePremium && isUserPremium() ){
            //             // กรณีที่หนังเป็น Premium และ User ก็เป็น Premium แสดงว่า User ดูหนังอยู่ในอีกจอนึงอยู่
            //             alertErrorByCode("0005")
            //         }else if( currentMediaRef.current.isCurrentEpisodePremium && !isUserPremium() ){
            //             // กรณีที่หนังเป็น Premium แต่ว่า User ไม่ได้เป็น Premium จะ Show หน้า Sub
            //             dispatch(premiumModalSetShow(true))
            //         }
            //     }
            // }else{
            
                goToEpisode(history, currentMediaRef.current.id, episode.episode, false)
            // }

        }

        }, 300)

    }

    const handleRental = () => {
        showLoading()
        callApiRentalBuy(currentMedia.details.product_id, currentMedia.details.price).then( (response) => {
            if( response.result.response && response.result.response.webPaymentUrl ){
                window.location.href = response.result.response.webPaymentUrl
            }else{
                alertErrorByCode(500)
            }
            hideLoading()
        })
    }

    return (
        // <div className={currentMedia.episode === episode.episode ? 'episode-content active' : 'episode-content'} onClick={ () => handleOnClick(episode) }>
        <div className={currentMedia.episode === episode.episode ? 'episode-content active' : 'episode-content'} onClick={ handleOnClick }>
            { (episode.thumbnail_url == null || episode.thumbnail_url == '') ?
                <div className='episode-content-image'>
                    <img src={DummyImage} alt=''/>
                </div>
                : 
                <div className='episode-content-image'>
                    <img src={episode.thumbnail_url} alt=''/>
                </div>
            }
            <div className='episode-content-name p-0'>
                {episode.name}
            </div>
        </div>
    )
}

export default EpisodeContent