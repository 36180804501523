import React from 'react'
import EpisodeContent from './EpisodeContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import DragScrollContainer from '../../components/helper/DragScrollContainer'
import useClientWidth from '../../hooks/useScreenWidth';
import { checkIsMobileWidth } from '../../helper/Helper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

const EpisodeContentList = ({ list }) => {

    const { t , i18n} = useTranslation('trans');

    const currentMedia = useSelector(state => state.media)

    const clientWidth = useClientWidth()

    const swiper = React.useRef(null);

    const setSwiper = (newSwiper) => {
        swiper.current = newSwiper;
    };

    React.useEffect(()=>{
        if(swiper.current) {
            let currentIndex = findActiveEpisodeIndex(list)
            swiper.current.slideTo(currentIndex)
        }
    }, [list]);

    const findActiveEpisodeIndex = ( episodeList ) => {
        return episodeList.findIndex( episode => episode.episode === currentMedia.episode )
    }


    return (
        <div className='episode-content-list-container'>
            <div className='episode-content-list-title'>
                {t('Episodes')}
            </div>
            {
                    checkIsMobileWidth(clientWidth)
                ?
                    <DragScrollContainer className='media-list'>
                        {
                            list.map( (episode, index) => {
                                return  <EpisodeContent key={episode.episode} episode={episode} />
                            })
                        }
                    </DragScrollContainer>
                
                :
                    <Swiper 
                        slidesPerView={'auto'} 
                        slidesPerGroup={2}
                        freeMode={true} 
                        navigation={true}
                        allowTouchMove={false}
                        autoHeight={true}
                        speed={500}
                        onSwiper={setSwiper}
                        className='horizontal-list episode-list'
                    >
                        {
                            list.map( (episode, index) => {
                                return  <SwiperSlide key={index}><EpisodeContent key={index} episode={episode}/></SwiperSlide>
                            })
                        }
                    </Swiper>
            }
        </div>
    )
}

export default EpisodeContentList
