import React, {useEffect, useState, useRef} from 'react'
import { connect } from 'react-redux'
import {chromecastSetSessionState, 
        chromecastSetMediaDuration, 
        chromecastSetMediaCurrentTime, 
        chromecastSetMediaRemainingTime, 
        chromecastSetMediaAvailableTextTracks, 
        chromecastSetMediaActiveTextTrack,
        chromecastSetMediaAvailableAudioTracks,
        chromecastSetMediaActiveAudioTrack,
        chromecastSetIsMuted,
        chromecastSetVolume,
        chromecastSetIsPlay,
        chromecastSetMediaTitle,
        chromecastSetDeviceName,
        chromecastSetMediaId,
        mediaSetCurrentTime,
        mediaSetCurrentAudioLanguage,
        mediaSetCurrentTextLanguage,
        } from '../../actions'
import { getTitle, getEpisodeList, startWatching, watching, endWatching, getEpisode } from '../../api'
import { CastPlayerController } from '../../helper/CastPlayerController'
import {loadScriptAsync, formatDurationString, isVideoPlayerPage, goToHomePage, findEpisodeIndexFromEpisodeId, consoleLogHelper, alertErrorByCode} from '../../helper/Helper'
import PlayButton from './PlayButton'
import PauseButton from './PauseButton'
import VolumeSlider from './VolumeSlider'
import MediaSeeker from './MediaSeeker'
import MediaRemainingTime from './MediaRemainingTime'
import MediaSubtitle from './MediaSubtitle'
import MediaAudio from './MediaAudio'
import BackButton from './BackButton'
import NextButton from './NextButton'
import CastInformation from './CastInformation'
import { useLocation, useHistory } from 'react-router-dom'
import {isUserPremium} from '../../helper/ReduxStoreHelper'
import { useCookies } from 'react-cookie'


const ChromeCastControlBar = ({ playerMedia, chromecastMediaId, chromecastEpisode, chromecastCurrentTime, chromecastSessionState, dispatch}) => {

    const [cookies, setCookie] = useCookies(['chromecastDetails']);

    const cookiesRef = useRef();
    cookiesRef.current = cookies;

    const chromecastCurrentTimeRef = useRef();
    chromecastCurrentTimeRef.current = chromecastCurrentTime;

    const location = useLocation();
    const history  = useHistory();
    

    const [intervalWatchingTimeId, setIntervalWatchingTimeId] = useState('')

    const intervalCallApiWatchingRef = useRef();
    intervalCallApiWatchingRef.current = intervalWatchingTimeId;

    useEffect( () => {

        if( chromecastSessionState === window.flixer.chromecast.sessionState.started ){
            if( isVideoPlayerPage(location.pathname) && playerMedia.id !== null ) {
                goToHomePage(history);
                dispatch(chromecastSetMediaId(playerMedia.id));
            }
            
        }else if( chromecastSessionState === window.flixer.chromecast.sessionState.resumed ){
            handleCurrentSessionConnected();
        }else{
            dispatch(chromecastSetMediaId(null));
        }

    }, [chromecastSessionState])


    useEffect( () => {
        handleChromeCastInitialize();
        loadCastSDK().then( () => {
            consoleLogHelper('Cast sender lib has been loaded successfully');
        })["catch"](function (e) {
            consoleLogHelper('Cast sender lib loading failed', e);
        });
    }, []);


    useEffect( () => {
        var  apiKeyCookie = document.cookie.replace(/(?:(?:^|.*;\s*)apiKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if( chromecastMediaId !== null ){
            getTitle(chromecastMediaId).then( (titleResponse) => {
                let mediaName = titleResponse.result.title.name;

                getEpisode(chromecastMediaId, playerMedia.episode).then( (response) =>  {
                    if( response.can_view === 1 ){
                        getEpisodeList(chromecastMediaId, apiKeyCookie).then( (response) => handleGetEpisodeListResponse(response, mediaName, titleResponse) )
                    }else{
                        alertErrorByCode("0005")
                    }
                } )
            } );
        }
    }, [chromecastMediaId])


    const handleCurrentSessionConnected = () => {

        if (window.cast && window.cast.framework) {
    
            const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

            if ( castSession && window.flixer.castPlayerController.isConnected()) {
                window.flixer.castPlayerController.getMediaStatus( () => {
                    initialChromecastMedia();

                    if( window.flixer.castPlayerController.getPlayerState() === window.flixer.chromecast.PLAYER_STATE.PLAYING ){
                        dispatch(chromecastSetIsPlay(true));
                        
                    }else{
                        dispatch(chromecastSetIsPlay(false));
                    }

                });
            }
        }
    }


    const initialChromecastMedia = async () => {
        let userSettings = cookies.userSettings
        
        if( userSettings && userSettings.language.audioLanguage ){
            await window.flixer.castPlayerController.setAudioLanguageFromLanguageString(userSettings.language.audioLanguage)
        }

        if( userSettings && userSettings.language.subtitleLanguage ){
            await window.flixer.castPlayerController.setTextLanguageFromLanguageString(userSettings.language.subtitleLanguage)
        }

        if( playerMedia.currentTime ){
            window.flixer.castPlayerController.seekRequest(playerMedia.currentTime);
        }


        let mediaDuration = window.flixer.castPlayerController.getMediaDuration();
        let mediaCurrentTime = window.flixer.castPlayerController.getCurrentMediaTime();
        let mediaRemainingTime = formatDurationString(mediaDuration - mediaCurrentTime);

        let allAvailableTracks = window.flixer.castPlayerController.getAllAvailableAudioAndTextTracks();
        let activeTracks = window.flixer.castPlayerController.getActiveAudioAndTextTrack();

        let castDeviceName = window.flixer.castPlayerController.getCastDeviceName();
        let mediaTitle = window.flixer.castPlayerController.getMediaTitle();

        dispatch(chromecastSetMediaDuration(mediaDuration));
        dispatch(chromecastSetMediaCurrentTime(mediaCurrentTime));
        dispatch(chromecastSetMediaRemainingTime(mediaRemainingTime));
        dispatch(chromecastSetMediaAvailableTextTracks(allAvailableTracks.text));
        dispatch(chromecastSetMediaActiveTextTrack(activeTracks.text));
        dispatch(chromecastSetMediaAvailableAudioTracks(allAvailableTracks.audio));
        dispatch(chromecastSetMediaActiveAudioTrack(activeTracks.audio));
        dispatch(chromecastSetIsMuted(window.flixer.castPlayerController.isMuted()));
        dispatch(chromecastSetVolume(window.flixer.castPlayerController.getMediaVolume()));
        dispatch(chromecastSetIsPlay(true));

        dispatch(chromecastSetMediaTitle(mediaTitle))
        dispatch(chromecastSetDeviceName(castDeviceName))

        // หลังจาก initial ค่าให้กับ chromecast แล้ว จะทำการ reset ค่า ถ้าไม่ทำการ reset จะทำให้เวลาเปลี่ยน media จะยัง initial ค่านี้อยู่
        dispatch(mediaSetCurrentTime(0));
        dispatch(mediaSetCurrentTextLanguage(null));
        dispatch(mediaSetCurrentAudioLanguage(null));
    }

    // เนื่องจากใน api episodelist จะไม่มีชื่อ media มาด้วย ทำให้ต้องเรียก title ก่อนและส่งค่า mediaName เข้ามา
    const handleGetEpisodeListResponse = (response, mediaName, titleResponse) => {
        let episodeList = response.result.episode_list;
        

        let source = episodeList.map( (episode) => {

            let mediaEpisodeName = "";
            let episodeItem = episode.episode

            titleResponse.result.episodes.forEach( ( titleEpisode ) => {
                if(titleEpisode.episode === episodeItem) {
                    mediaEpisodeName = titleEpisode.name;
                }
                
            });

            if (episode.playlist_license_url == null) { 
                return {
                    src : episode.playlist,
                    type: 'application/x-mpegURL',
                    name: mediaName,
                    episodeName: mediaEpisodeName,
                    episode: episode.episode,
                    mediaId: chromecastMediaId, // ต้องมีไว้กรณี refresh เว็บ
                    licenseUrl: episode.playlist_license_url,
                    coverImage: response.result.title_cover_image_url,
                    titlePremium: episode.title_premium,
                    episodePremium: episode.ep_premium
                }
            } else {
                return {
                    src : episode.playlist,
                    type: 'application/dash+xml',
                    name: mediaName,
                    episodeName: mediaEpisodeName,
                    episode: episode.episode,
                    mediaId: chromecastMediaId, // ต้องมีไว้กรณี refresh เว็บ
                    licenseUrl: episode.playlist_license_url,
                    coverImage: response.result.title_cover_image_url,
                    titlePremium: episode.title_premium,
                    episodePremium: episode.ep_premium
                }
            }
        })

        let startIndex = findEpisodeIndexFromEpisodeId(episodeList, playerMedia.episode);

        window.flixer.castPlayerController.loadQueueMedia(source, startIndex, () => { initialChromecastMedia();consoleLogHelper('loadQueueMedia Loaded!!') });
    }

    const handleStartWatching = (titlePremium, episodePremium, mediaId, episode) => {
        startWatching(titlePremium, episodePremium ,mediaId, episode).then(() => {
            watching(mediaId, episode, 1).then( () => {
            })

            clearInterval(intervalCallApiWatchingRef.current)

            var watchingTime = 0;

            const intervalTimeId = setInterval(() => {
                try {
                    if(playerMedia.currentTime != null) {
                        // var  currentTimeWatching = Math.round(mediaCurrentTime)
    
                        watchingTime = watchingTime + 1
    
                        consoleLogHelper("watchingTime ChromeCast", watchingTime)
                        if(watchingTime % 10 === 0) {
                            // watching(playerMedia.id, playerMedia.episode,  currentTimeWatching ) 
                            watching( mediaId, episode,  Math.round(window.flixer.castPlayerController.getCurrentMediaTime()) ) 
                            consoleLogHelper("call : ", "watching")
                        }
                    }  
                } catch (error) {
                }
    
            }, 1000)
            setIntervalWatchingTimeId(intervalTimeId)
        })
    }

    const handleEndWatching = (mediaId, episode, mediaDuration) => {
        return new Promise( async (resolve) => {
            let currentTime = Math.round(mediaDuration);
            if( currentTime < 0 ){
                currentTime = 0
            }
            await watching(mediaId, episode,  currentTime)
            await endWatching()
            resolve()
        })
    }


    const loadCastSDK = () => {
        if (window['cast'] && window['cast']['framework']) {
          return Promise.resolve();
        }
        return loadScriptAsync('//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
    }


    const handleChromeCastInitialize = () => {

        if( isUserPremium() ){

            window['__onGCastApiAvailable'] = (isAvailable) => {
                if (isAvailable && window.chrome.cast) {
    
                    var options = {
                        receiverApplicationId   : 'E02DC2E3',
                        autoJoinPolicy          : window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                        resumeSavedSession      : true,
                    };
                    
                    window.cast.framework.CastContext.getInstance().setOptions(options);
                    window.flixer.castPlayerController = new CastPlayerController();
    
                    var context = window.cast.framework.CastContext.getInstance();

                    // กรณีที่ NO_SESSION จะต้องตรวจสอบว่ามี cookie เดิมค้างอยู่หรือเปล่า ถ้ามีแสดงว่าเป็น session หลุดหรือว่าออกไปปิด session นอกเว็บ ต้องทำการ end watching ของตัวนั้น
                    if( context.getSessionState() === "NO_SESSION"){

                        let chromecastDetails = cookiesRef.current['chromecastDetails']
            
                        if( chromecastDetails && typeof chromecastDetails === 'object' && Object.keys(chromecastDetails).length !== 0 ){

                            let chromecastPreviousMediaId       = chromecastDetails.previousMediaId
                            let chromecastPreviousEpisode       = chromecastDetails.previousEpisode
                            let chromecastPreviousMediaDuration = chromecastDetails.previousMediaDuration

                            handleEndWatching(chromecastPreviousMediaId, chromecastPreviousEpisode, chromecastPreviousMediaDuration)
                            clearInterval(intervalCallApiWatchingRef.current)
                            setCookie('chromecastDetails', {}, { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault})   
                        }
                    }


                    context.addEventListener(
                        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
                        (event) => {
                            switch (event.sessionState) {
                                case window.cast.framework.SessionState.SESSION_STARTED:
                                    consoleLogHelper('CastContext: CastSession SESSION_STARTED');
                                    dispatch(chromecastSetSessionState(window.flixer.chromecast.sessionState.started));
                                    // Clear cookies chromecastdetail ออก เนื่องจากเป็นการเริ่มใหม่
                                    setCookie('chromecastDetails', {}, { path: '/', maxAge: 31622400 , domain: window.flixer.cookieDomainDefault})
                                    break;
                                case window.cast.framework.SessionState.SESSION_RESUMED:
                                    consoleLogHelper('CastContext: CastSession SESSION_RESUMED');
                                    dispatch(chromecastSetSessionState(window.flixer.chromecast.sessionState.resumed));
                                    break;
                                case window.cast.framework.SessionState.SESSION_ENDED:
                                    consoleLogHelper('CastContext: CastSession disconnected');
                                    dispatch(chromecastSetSessionState(window.flixer.chromecast.sessionState.ended));

                                    let chromecastDetails = cookiesRef.current['chromecastDetails']

                                    let chromecastPreviousMediaId       = chromecastDetails.previousMediaId
                                    let chromecastPreviousEpisode       = chromecastDetails.previousEpisode

                                    // chromecastPrevious จะมีค่าเดียวกับ media ปัจจุบัน กรณีที่ยังเล่นไม่จบ
                                    handleEndWatching(chromecastPreviousMediaId, chromecastPreviousEpisode, chromecastCurrentTimeRef.current)
                                    clearInterval(intervalCallApiWatchingRef.current)
                                    setCookie('chromecastDetails', {}, { path: '/', maxAge: 31622400 , domain: window.flixer.cookieDomainDefault})
                                    break;
                                default :
                                    break;
                            }
                        }
                    );

                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                        (data) => {

                            let mediaDuration       = window.flixer.castPlayerController.getMediaDuration();
                            let mediaCurrentTime    = data.value
                            let mediaRemainingTime  = formatDurationString(mediaDuration - mediaCurrentTime);

                            // จะไม่ update ค่า กรณีที่ currentTime ล่าสุดไม่เท่ากับ 0 และ ค่าใหม่เป็น 0 
                            // เนื่องจากถ้า update ค่่าไป ตอนเรียก endwatching ค่า currentTime ทีี่ได้จะเป็น 0 เพราะต้องรอจนขึ้นหนังใหม่ถึงจะเรียก endWatching
                            if( mediaCurrentTime === 0 && chromecastCurrentTimeRef.current !== 0 ){
                            }else{
                                dispatch(chromecastSetMediaDuration(mediaDuration));
                                dispatch(chromecastSetMediaCurrentTime(mediaCurrentTime));
                                dispatch(chromecastSetMediaRemainingTime(mediaRemainingTime));
                            }
                        }
                    )
                
                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
                        async (data) => {

                            let chromecastDetails = cookiesRef.current['chromecastDetails']
                            let chromecastPreviousMediaId = chromecastDetails.previousMediaId
                            let chromecastPreviousEpisode = chromecastDetails.previousEpisode

                            let currentMediaTime = chromecastCurrentTimeRef.current;

                            if( data.value && data.value.customData ){

                                let currentMediaId       = data.value.customData.mediaId
                                let currentEpisode       = data.value.customData.episode
                                let currentMediaDuration = data.value.duration
                                let titlePremium       = data.value.customData.titlePremium
                                let episodePremium       = data.value.customData.episodePremium

                                
                                // กรณีที่ไม่มีข้อมูลใน chromecastPrevious แสดงว่าพึ่งเริ่มดูหนังใหม่
                                if( !chromecastPreviousMediaId && !chromecastPreviousEpisode){
                                    handleStartWatching(titlePremium, episodePremium, currentMediaId, currentEpisode)
                                    // Set ค่า media ปัจจุบันให้เป็น previous ไว้ใช้ตรวจสอบตอนเริ่มหนังใหม่
                                    setCookie('chromecastDetails', { previousMediaId: currentMediaId, previousEpisode: currentEpisode, previousMediaDuration: currentMediaDuration }, { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault })
                                }
                                // กรณีที่มีข้อมูล chromecastPrevious และข้อมูลไม่ตรงกับ Media ปัจจุบัน แสดงว่า ดูเรื่องอื่นมาก่อนหน้านี้
                                else if( chromecastPreviousMediaId && chromecastPreviousEpisode && 
                                    (currentMediaId !== chromecastPreviousMediaId || currentEpisode !== chromecastPreviousEpisode )
                                ){
                                    await handleEndWatching(chromecastPreviousMediaId, chromecastPreviousEpisode, currentMediaTime)
                                    handleStartWatching(titlePremium, episodePremium, currentMediaId, currentEpisode)
                                    // Set ค่า media ปัจจุบันให้เป็น previous ไว้ใช้ตรวจสอบตอนเริ่มหนังใหม่
                                    setCookie('chromecastDetails', { previousMediaId: currentMediaId, previousEpisode: currentEpisode, previousMediaDuration: currentMediaDuration }, { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault })
                                }

                            // จะเกิดขึ้นกรณีที่เปลี่ยนเรื่อง หรือว่าหมด Queue
                            }else if( data.value === null ){
                                
                                if( chromecastPreviousMediaId && chromecastPreviousEpisode ){
                                    handleEndWatching(chromecastPreviousMediaId, chromecastPreviousEpisode, currentMediaTime)
                                    clearInterval(intervalCallApiWatchingRef.current)
                                    setCookie('chromecastDetails', {}, { path: '/', maxAge: 31622400, domain: window.flixer.cookieDomainDefault })

                                    // เซ็ตค่าเวลาให้เป็น 0 ไม่งั้นตอนจบ Queue เวลาจะค้าง เนื่องจากเราดักไว้ไม่ได้ set ค่าใน event CURRENT_TIME_CHANGED
                                    let mediaRemainingTime  = formatDurationString(0);
                                    dispatch(chromecastSetMediaCurrentTime(0));
                                    dispatch(chromecastSetMediaRemainingTime(mediaRemainingTime));
                                }
                            }
                        }
                    )


                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.QUEUE_DATA_CHANGED,
                        () => { 
                            let castDeviceName = '';
                            let mediaTitle = '';
    
                            const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    
                            if( castSession ){
                                castDeviceName = window.flixer.castPlayerController.getCastDeviceName();
                                mediaTitle = window.flixer.castPlayerController.getMediaTitle();
                            }
    
                            dispatch(chromecastSetMediaTitle(mediaTitle))
                            dispatch(chromecastSetDeviceName(castDeviceName))
                        }
                    );
    
                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                        () => {
                            if (window.flixer.castPlayerController.isPaused()) {
                                let customData = window.flixer.castPlayerController.getMediaCustomData()
                                if( customData && customData.episode && customData.mediaId ){
                                    watching( customData.mediaId, customData.episode,  Math.round(window.flixer.castPlayerController.getCurrentMediaTime()) )
                                }
                                dispatch(chromecastSetIsPlay(false))
                            } else {
                                dispatch(chromecastSetIsPlay(true))
                            }
                        }
                    );
                
                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
                        () => {
                            dispatch(chromecastSetIsMuted(window.flixer.castPlayerController.isMuted()))
                        }
                    );
            
                    window.flixer.castPlayerController.remotePlayerController.addEventListener(
                        window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
                        () => {
                            dispatch(chromecastSetVolume(window.flixer.castPlayerController.getMediaVolume())) 
                        }
                    );
    
                }
            }
        };

    }


    return (
        <div className='chromecast-control-bar' hidden={chromecastSessionState === null || chromecastSessionState === window.flixer.chromecast.sessionState.ended} >
            <CastInformation />
            <BackButton />
            <PlayButton/>
            <PauseButton/>
            <NextButton />
            <VolumeSlider />
            <MediaSeeker />
            <MediaRemainingTime />
            <MediaSubtitle />
            <MediaAudio />
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    playerMedia: state.media,
    chromecastMediaId: state.chromecast.mediaId,
    chromecastEpisode: state.chromecast.episode,
    chromecastCurrentTime: state.chromecast.media.currentTime,
    chromecastSessionState: state.chromecast.sessionState
})
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChromeCastControlBar)